export const projectItems = [
  {
    url: "https://pulsespot.cvassia.com/",
    image: "assets/images/pulseSpot.jpg",
    header: "PulseSpot",
    year: "2023  App in progress...",
    description: "An app presenting all the current events of towns in Greece.",
    descriptionSecondLine:
      "Providers of events can add, edit or delete events and users can browse and save in their favorites all the events that are interested in. -- Best view for now in mobile --",
    stacks: ["Remix", "Node", "Prisma", "Typescript"],
  },
  {
    url: "https://notes.cvassia.com/",
    image: "assets/images/notesApp.png",
    header: "Notes App",
    year: "2023",
    description: "A note-taking app. ",
    descriptionSecondLine: "Add and Edit everyday tasks and thoughts.",
    stacks: ["Remix", "Node", "Prisma", "Typescript"],
  },
  {
    url: "https://art4kids.gr/",
    image: "assets/images/manitou.jpg",
    header: "ART4Kids",
    year: 2021,
    description: "Portfolio website of a creative musician from Greece,",
    descriptionSecondLine:
      "specializes in composing and performing music for kids.",
    stacks: ["Javascript", "Wordpress"],
  },
  {
    url: "https://www.hrlab.de/en",
    image: "assets/images/hrlab.jpg",
    header: "HRlab",
    year: "2020 - now",
    description:
      "Part of the frontend team responsible for the design and implementation",
    descriptionSecondLine:
      "of the HR software needed for the employee management.",
    stacks: ["React", "Redux", "Styled Components", "Vite"],
  },
  {
    url: "https://vinzenzschechner.com/",
    image: "assets/images/vinzenz.png",
    header: "Vinzenz Schechner",
    year: 2021,
    description: "Portfolio website of a Costume Designer in Berlin",
    descriptionSecondLine:
      "specializes in creating costumes for Opera and Theater.",
    stacks: ["Javascript", "Wordpress"],
  },
  {
    url: "https://weather.cvassia.com/",
    image: "assets/images/weather.png",
    header: "Weather app",
    year: "2019",
    description: "National and local weather widget forecast for cities.",
    descriptionSecondLine:
      "6 days forecast. Max and Min Temperature, Sunset, Sunrise, Wind, Humidity",
    stacks: ["Javascript", "SCSS", "Google API"],
  },

  // {
  //   url: "https://todo.cvassia.com",
  //   image: "assets/images/weat.GIF",
  //   header: "ToDO App",
  //   year: "2019",
  //   description: "Simple to do app for everyday tasks.",
  //   descriptionSecondLine: "",
  //   stacks: ["Javascript", "Tailwind CSS"],
  // },
];
